body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#mainmenu .mobile-menu {
  display: none !important;
}

a.btn-custom.signIn:hover,
a.btn-custom.signUp:hover {
  box-shadow: none !important;
}

a.btn-custom.signUp {
  border: 1px solid #3f51b5 !important;
}
a.btn-custom.signIn:hover {
  background-color: #3f51b5 !important;
  color: #fff !important;
}

a.btn-custom.signUp:hover {
  background-color: #fff !important;
  color: #3f51b5 !important;
  border: 1px solid #3f51b5 !important;
}

@media only screen and (max-width: 425px) {
  .header-mobile .btn-custom.signIn,
  .header-mobile .btn-custom.signUp {
    display: none;
  }

  #mainmenu .mobile-menu {
    display: block !important;
  }

  #mainmenu .mobile-menu .signIn,
  #mainmenu .mobile-menu .signUp {
    font-weight: 600;
    color: #3f51b5;
  }
}
