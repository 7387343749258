.bg-color,
a.btn-custom,
.btn-custom {
    background-color: #3F51B5;
}
.font-weight-bold {
    font-weight: 600 !important;
}
#mainmenu li:hover {
    cursor: pointer;
}
#owl-logo .owl-item {
    width: 200px!important;
}

#video-container > video {
  width: 100%;
}
.f-box i {
    margin: 0 !important;
    padding: 0 !important;
    min-width: 80px;
    text-align: center;
}
.smaller .signIn {
    border: solid 1px #3f51b5;
    background: transparent!important;
    color: #3f51b5!important;
}